import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'

import './layout.css'
import Footer from './footer'

class Layout extends React.Component {
  render () {
    return (
      <div className="container">
        <div className="warning">
          <div className="warning__inner">
            ScreenTray is no longer supported.
            Please <Link className="warning__link" to="/contact-us/">contact us</Link> if you have any questions.
          </div>
        </div>
        <div className="header">
          <div className="limiter">
            <Link className="logo" to="/">ScreenTray</Link>
          </div>
        </div>
        {this.props.children}
        <Footer/>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
