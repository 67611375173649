import React from 'react'
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types'

import siteConfig from '../utils/site-config'
import favicon from '../assets/images/favicon.png'

const Meta = ({title}) => {
  const pageTitle = title ? `${title} | ${siteConfig.title}` : siteConfig.title
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={siteConfig.description}/>
      <meta property="og:title" content={pageTitle}/>
      <meta property="og:description" content={siteConfig.description}/>
      <meta property="og:image" content={siteConfig.shareImageURL}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <link rel="shortcut icon" href={favicon}/>
    </Helmet>
  )
}

Meta.propTypes = {
  title: PropTypes.string
}

export default Meta
