import React from 'react'
import {Link} from 'gatsby'

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-sections limiter">
        <div className="footer-section footer-section--main">
          <Link className="logo logo--footer" to="/">ScreenTray</Link>
          <div>
            {/* eslint-disable-next-line */}
            <a
              href="https://www.facebook.com/ScreenTray-2148194755287899/"
              target="_blank"
              className="footer-section__social-link footer-section__social-link--facebook"
              rel="noopener noreferrer"
            />
            {/* eslint-disable-next-line */}
            <a
              href="https://twitter.com/ScreenTray/"
              target="_blank"
              className="footer-section__social-link footer-section__social-link--twitter"
              rel="noopener noreferrer"
            />
          </div>
        </div>
        <div className="footer-section">
          <div className="footer-section__heading">Product</div>
          <div>
            <a
              href="https://trello.com/b/SV9tllxC/screentray-product-roadmap"
              target="_blank"
              className="footer-section__link"
              rel="noopener noreferrer"
            >
              Roadmap
            </a>
          </div>
          <div>
            <a
              href="https://github.com/DSnopov/screentray-distribution/blob/master/CHANGELOG.md"
              target="_blank"
              className="footer-section__link"
              rel="noopener noreferrer"
            >
              Changelog
            </a>
          </div>
        </div>
        <div className="footer-section">
          <div className="footer-section__heading">Support</div>
          <div>
            <Link className="footer-section__link" to="/contact-us/">Contact Us</Link>
          </div>
          <div>
            <Link className="footer-section__link" to="/coming-soon/">Help Center</Link>
          </div>
        </div>
        <div className="footer-section">
          <div className="footer-section__heading">Legal</div>
          <div>
            <Link className="footer-section__link" to="/privacy-policy/">Privacy Policy</Link>
          </div>
          <div>
            <Link className="footer-section__link" to="/license-agreement/">License Agreement</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
